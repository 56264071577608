<template>
  <div>
    <div class="text-normal">
      <h4 class="header-tablepage">
        New Ticket
        <span class="badge badge-new badge-pill">New</span>
      </h4>
      <div class="title-tabs mt-3">Ticket Form</div>
      <b-container class="no-gutters bg-white">
        <div class="pt-3 pb-15">
          <FormInputList
            :items="formDetail.fields || []"
            :depth="0"
            :errors="$v.formDetail.fields"
            :currentPage="currentPage"
          />

          <div class="pagination-wrapper" v-if="hasPage">
            <div>{{ currentPage }} / {{ totalPage }}</div>
            <div class="pagination-action">
              <b-button
                class="btn main-color btn-save w-100px"
                :disabled="currentPage == 1"
                @click="prevPage"
              >
                Previous</b-button
              >

              <b-button
                class="btn main-color btn-save w-100px"
                :disabled="currentPage == totalPage"
                @click="nextPage"
              >
                Next</b-button
              >
            </div>
          </div>
        </div>
      </b-container>
    </div>
    <FooterAction routePath="/ticket-activity" @submit="sendForm(0)" />
  </div>
</template>

<script>
import FormInputList from "@/components/ticket/activity/FormInputList";
import { requiredIf } from "vuelidate/lib/validators";

export default {
  components: {
    FormInputList,
  },
  data() {
    return {
      isLoading: true,
      isSubmit: false,
      currentPage: 1,
      formDetail: {
        id: 1,
        name: "",
        url_key: "",
        valid_form: null,
        valid_to: null,
        description: "",
        active: 1,
        is_default: 0,
        fields: [],
      },
    };
  },
  computed: {
    formStatus() {
      return this.formDetail.fields.find((el) => el.ticket_field_id == 5).value;
    },
    hasPage() {
      return this.formDetail.fields.some((el) => el.field_type_id == 14);
    },
    totalPage() {
      return this.formDetail.fields[this.formDetail.fields.length - 1].page;
    },
  },
  validations() {
    return {
      formDetail: {
        fields: {
          $each: {
            value: {
              required: requiredIf(function (item) {
                const isSubmit = Boolean(item.is_require_submit);
                const isClose =
                  this.formStatus == 4 && Boolean(item.is_require_close);
                return isSubmit || isClose;
              }),
            },
            field_dependent: {
              $each: {
                value: {
                  required: requiredIf(function (item) {
                    const isSubmit =
                      Boolean(item.is_require_submit) && item.isActive;
                    const isClose =
                      this.formStatus == 4 &&
                      Boolean(item.is_require_close) &&
                      item.isActive;
                    return isSubmit || isClose;
                  }),
                },
                field_dependent: {
                  $each: {
                    value: {
                      required: requiredIf(function (item) {
                        const isSubmit =
                          Boolean(item.is_require_submit) && item.isActive;
                        const isClose =
                          this.formStatus == 4 &&
                          Boolean(item.is_require_close) &&
                          item.isActive;
                        return isSubmit || isClose;
                      }),
                    },
                  },
                },
              },
            },
          },
        },
      },
    };
  },
  async created() {
    this.getFormDetail();
  },
  methods: {
    nextPage() {
      this.currentPage += 1;
      this.$forceUpdate();
    },
    prevPage() {
      this.currentPage -= 1;
      this.$forceUpdate();
    },
    async getFormDetail() {
      const { data } = await this.axios(`/ticket/form/1`);
      if (data.result === 1) {
        data.detail.fields.forEach((element) => {
          element.field_dependent = this.mapFieldFromDepth(
            element.field_dependent,
            element.id
          );
        });
        this.formDetail = data.detail;
        this.formDetail.fields = this.mappingValue(this.formDetail.fields);
      }
    },
    mapFieldFromDepth(fields, parentId = null) {
      if (!fields?.length) return [];
      return fields
        .filter((el) => el.parent_id === parentId)
        .map((el) => {
          return {
            ...el,
            field_dependent: this.mapFieldFromDepth(
              fields,
              el.ticket_form_field_id
            ),
          };
        });
    },
    mappingValue(items, page = 1) {
      if (!items.length) return;
      return items.map((el, index) => {
        if (items[index - 1] && items[index - 1].field_type_id == 14) page += 1;

        if (el.field_dependent?.length)
          el.field_dependent = this.mappingValue(el.field_dependent, page);

        let value = null;

        if ([2, 3, 4].includes(el.field_type_id)) {
          const defaultValue = el.field_choice
            .filter((val) => Boolean(val.isDefault))
            .map((val) => {
              return el.field_type_id == 3 ? { ...val } : val.id;
            });

          value = [2, 3].includes(el.field_type_id)
            ? defaultValue || []
            : defaultValue[0] || null;
        }

        return { ...el, value, displayValue: "", page };
      });
    },

    flatFields(data) {
      return data
        .reduce((acc, x) => {
          acc = acc.concat(x);
          if (x.field_dependent) {
            acc = acc.concat(this.flatFields(x.field_dependent));
            x.field_dependent = [];
          }
          return acc;
        }, [])
        .map((el) => {
          let value = el.value;
          if (el.field_type_id == 2)
            value = el.value.map((item) => item).join(",");
          else if (el.field_type_id == 3)
            value = el.value.map((item) => item.id).join(",");
          return {
            id: 0,
            ticket_form_field_id: el.ticket_form_field_id || el.id,
            ticket_field_id: el.ticket_field_id,
            value,
            is_default_field: el.is_default_field,
            field_dependent: el.field_dependent?.length
              ? this.flatFields(el.field_dependent)
              : [],
          };
        });
    },

    async sendForm() {
      this.$v.formDetail.$touch();
      if (this.$v.formDetail.$error) {
        return;
      }

      this.$bus.$emit("showLoading");

      const field_answer = this.flatFields(this.formDetail.fields);

      const payload = {
        id: 0,
        ticket_form_id: 1,
        field_answer,
      };

      const { data } = await this.axios.post(`/ticket`, payload);

      this.$bus.$emit("hideLoading");
      if (data.result == 1) {
        this.isLoading = false;

        this.successAlert().then(() => {
          this.$router.push("/ticket-activity");
        });
      } else {
        this.isLoading = false;
        this.errorAlert(data.message);
      }
    },

    async getBranchList(field, id) {
      await this.$store.dispatch("getBranch", parseInt(id));
      field.branch_list = this.$store.state.coupon.branchList.detail;
      this.branchList = this.$store.state.coupon.branchList.detail;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .text-float-normal .input-custom {
  label {
    color: #5b5b5b;
    font-size: 16px;
    font-weight: 100;
  }
}

#footer {
  position: fixed;
  bottom: 0;
  left: 256px;
  right: 0;
  height: 10%;
  padding: 15px;
  background: #fff;
}

.badge-new {
  background-color: rgb(39, 189, 167);
  color: white;
  padding: 0.4em 0.6em;
  font-size: 12px;
}

.header-tablepage {
  display: flex;
  align-items: center;
  gap: 12px;
}

.pb-15 {
  padding-bottom: 60px;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .pagination-action {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}

.w-100px {
  width: 101px !important;
  min-width: 0 !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
